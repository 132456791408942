import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/technology/run-commands-for-windows-7",
  "date": "2013-12-03",
  "title": "RUN COMMANDS FOR WINDOWS 7",
  "author": "admin",
  "tags": ["technology", "windows"],
  "featuredImage": "feature.jpg",
  "excerpt": "A run command is the name of the file that starts an application, which is an executable for a program."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Knowing the run command for a particular program in Windows 7 can be helpful if Windows isn't working properly but you do have access to a command line interface or if you'd just like to access a particular program more quickly.`}</p>
    <p>{`Many Windows 7 run command lists online incorrectly include Command Prompt commands or Control Panel `}<strong parentName="p">{`commands`}</strong>{` as run commands.`}</p>
    <p>{`Here is the list of run commands for Windows 7.`}</p>
    <h2>{`List of Run Commands in Windows 7`}</h2>
    <table>
    <tbody>
        <tr>
            <td><b>Program Name</b></td>
            <td><b>Run Command</b></td>
        </tr>
        <tr>
            <td>About Windows</td>
            <td>winver</td>
        </tr>
        <tr>
            <td>Add a Device</td>
            <td>devicepairingwizard</td>
        </tr>
        <tr>
            <td>Add Hardware Wizard</td>
            <td>hdwwiz</td>
        </tr>
        <tr>
            <td>Advanced User Accounts</td>
            <td>netplwiz</td>
        </tr>
        <tr>
            <td>Authorization Manager</td>
            <td>azman</td>
        </tr>
        <tr>
            <td>Backup and Restore</td>
            <td>sdclt</td>
        </tr>
        <tr>
            <td>Bluetooth File Transfer</td>
            <td>fsquirt</td>
        </tr>
        <tr>
            <td>Calculator</td>
            <td>calc</td>
        </tr>
        <tr>
            <td>Certificates</td>
            <td>certmgr</td>
        </tr>
        <tr>
            <td>Change Computer Performance Settings</td>
            <td>systempropertiesperformance</td>
        </tr>
        <tr>
            <td>Change Data Execution Prevention Settings</td>
            <td>systempropertiesdataexecutionprevention</td>
        </tr>
        <tr>
            <td>Change Printer Settings</td>
            <td>printui</td>
        </tr>
        <tr>
            <td>Character Map</td>
            <td>charmap</td>
        </tr>
        <tr>
            <td>ClearType Tuner</td>
            <td>cttune</td>
        </tr>
        <tr>
            <td>Color Management</td>
            <td>colorcpl</td>
        </tr>
        <tr>
            <td>Command Prompt</td>
            <td>cmd</td>
        </tr>
        <tr>
            <td>Component Services</td>
            <td>comexp</td>
        </tr>
        <tr>
            <td>Component Services</td>
            <td>dcomcnfg</td>
        </tr>
        <tr>
            <td>Computer Management</td>
            <td>compmgmt</td>
        </tr>
        <tr>
            <td>Computer Management</td>
            <td>compmgmtlauncher</td>
        </tr>
        <tr>
            <td>Connect to a Network Projector</td>
            <td>netproj</td>
        </tr>
        <tr>
            <td>Connect to a Projector</td>
            <td>displayswitch</td>
        </tr>
        <tr>
            <td>Control Panel</td>
            <td>control</td>
        </tr>
        <tr>
            <td>Create A Shared Folder Wizard</td>
            <td>shrpubw</td>
        </tr>
        <tr>
            <td>Create a System Repair Disc</td>
            <td>recdisc</td>
        </tr>
        <tr>
            <td>Credential Backup and Restore Wizard</td>
            <td>credwiz</td>
        </tr>
        <tr>
            <td>Data Execution Prevention</td>
            <td>systempropertiesdataexecutionprevention</td>
        </tr>
        <tr>
            <td>Default Location</td>
            <td>locationnotifications</td>
        </tr>
        <tr>
            <td>Device Manager</td>
            <td>devmgmt</td>
        </tr>
        <tr>
            <td>Device Pairing Wizard</td>
            <td>devicepairingwizard</td>
        </tr>
        <tr>
            <td>Diagnostics Troubleshooting Wizard</td>
            <td>msdt</td>
        </tr>
        <tr>
            <td>Digitizer Calibration Tool</td>
            <td>tabcal</td>
        </tr>
        <tr>
            <td>DirectX Diagnostic Tool</td>
            <td>dxdiag</td>
        </tr>
        <tr>
            <td>Disk Cleanup</td>
            <td>cleanmgr</td>
        </tr>
        <tr>
            <td>Disk Defragmenter</td>
            <td>dfrgui</td>
        </tr>
        <tr>
            <td>Disk Management</td>
            <td>diskmgmt</td>
        </tr>
        <tr>
            <td>Display</td>
            <td>dpiscaling</td>
        </tr>
        <tr>
            <td>Display Color Calibration</td>
            <td>dccw</td>
        </tr>
        <tr>
            <td>Display Switch</td>
            <td>displayswitch</td>
        </tr>
        <tr>
            <td>DPAPI Key Migration Wizard</td>
            <td>dpapimig</td>
        </tr>
        <tr>
            <td>Driver Verifier Manager</td>
            <td>verifier</td>
        </tr>
        <tr>
            <td>Ease of Access Center</td>
            <td>utilman</td>
        </tr>
        <tr>
            <td>EFS REKEY Wizard</td>
            <td>rekeywiz</td>
        </tr>
        <tr>
            <td>Encrypting File System Wizard</td>
            <td>rekeywiz</td>
        </tr>
        <tr>
            <td>Event Viewer</td>
            <td>eventvwr</td>
        </tr>
        <tr>
            <td>Fax Cover Page Editor</td>
            <td>fxscover</td>
        </tr>
        <tr>
            <td>File Signature Verification</td>
            <td>sigverif</td>
        </tr>
        <tr>
            <td>Font Viewer</td>
            <td>fontview3</td>
        </tr>
        <tr>
            <td>Getting Started</td>
            <td>gettingstarted</td>
        </tr>
        <tr>
            <td>IExpress Wizard</td>
            <td>iexpress</td>
        </tr>
        <tr>
            <td>Import to Windows Contacts</td>
            <td>wabmig1</td>
        </tr>
        <tr>
            <td>Install or Uninstall Display Languages</td>
            <td>lusrmgr</td>
        </tr>
        <tr>
            <td>Internet Explorer</td>
            <td>iexplore1</td>
        </tr>
        <tr>
            <td>iSCSI Initiator Configuration Tool</td>
            <td>iscsicpl</td>
        </tr>
        <tr>
            <td>iSCSI Initiator Properties</td>
            <td>iscsicpl</td>
        </tr>
        <tr>
            <td>Language Pack Installer</td>
            <td>lpksetup</td>
        </tr>
        <tr>
            <td>Local Group Policy Editor</td>
            <td>gpedit</td>
        </tr>
        <tr>
            <td>Local Security Policy</td>
            <td>secpol</td>
        </tr>
        <tr>
            <td>Local Users and Groups</td>
            <td>lusrmgr</td>
        </tr>
        <tr>
            <td>Location Activity</td>
            <td>locationnotifications</td>
        </tr>
        <tr>
            <td>Magnifier</td>
            <td>magnify</td>
        </tr>
        <tr>
            <td>Malicious Software Removal Tool</td>
            <td>mrt</td>
        </tr>
        <tr>
            <td>Manage Your File Encryption Certificates</td>
            <td>rekeywiz</td>
        </tr>
        <tr>
            <td>Math Input Panel</td>
            <td>mip1</td>
        </tr>
        <tr>
            <td>Microsoft Management Console</td>
            <td>mmc</td>
        </tr>
        <tr>
            <td>Microsoft Support Diagnostic Tool</td>
            <td>msdt</td>
        </tr>
        <tr>
            <td>NAP Client Configuration</td>
            <td>napclcfg</td>
        </tr>
        <tr>
            <td>Narrator</td>
            <td>narrator</td>
        </tr>
        <tr>
            <td>New Scan Wizard</td>
            <td>wiaacmgr</td>
        </tr>
        <tr>
            <td>Notepad</td>
            <td>notepad</td>
        </tr>
        <tr>
            <td>ODBC Data Source Administrator</td>
            <td>odbcad32</td>
        </tr>
        <tr>
            <td>ODBC Driver Configuration</td>
            <td>odbcconf</td>
        </tr>
        <tr>
            <td>On-Screen Keyboard</td>
            <td>osk</td>
        </tr>
        <tr>
            <td>Paint</td>
            <td>mspaint</td>
        </tr>
        <tr>
            <td>Performance Monitor</td>
            <td>perfmon</td>
        </tr>
        <tr>
            <td>Performance Options</td>
            <td>systempropertiesperformance</td>
        </tr>
        <tr>
            <td>Phone Dialer</td>
            <td>dialer</td>
        </tr>
        <tr>
            <td>Presentation Settings</td>
            <td>presentationsettings</td>
        </tr>
        <tr>
            <td>Print Management</td>
            <td>printmanagement</td>
        </tr>
        <tr>
            <td>Printer Migration</td>
            <td>printbrmui</td>
        </tr>
        <tr>
            <td>Printer User Interface</td>
            <td>printui</td>
        </tr>
        <tr>
            <td>Private Character Editor</td>
            <td>eudcedit</td>
        </tr>
        <tr>
            <td>Problem Steps Recorder</td>
            <td>psr</td>
        </tr>
        <tr>
            <td>Protected Content Migration</td>
            <td>dpapimig</td>
        </tr>
        <tr>
            <td>Registry Editor</td>
            <td>regedit</td>
        </tr>
        <tr>
            <td>regedt324</td>
        </tr>
        <tr>
            <td>Remote Access Phonebook</td>
            <td>rasphone</td>
        </tr>
        <tr>
            <td>Remote Desktop Connection</td>
            <td>mstsc</td>
        </tr>
        <tr>
            <td>Resource Monitor</td>
            <td>resmon</td>
        </tr>
        <tr>
            <td>perfmon /res</td>
        </tr>
        <tr>
            <td>Resultant Set of Policy</td>
            <td>rsop</td>
        </tr>
        <tr>
            <td>Securing the Windows Account Database</td>
            <td>syskey</td>
        </tr>
        <tr>
            <td>Services</td>
            <td>services</td>
        </tr>
        <tr>
            <td>Set Program Access and Computer Defaults</td>
            <td>computerdefaults</td>
        </tr>
        <tr>
            <td>Share Creation Wizard</td>
            <td>shrpubw</td>
        </tr>
        <tr>
            <td>Shared Folders</td>
            <td>fsmgmt</td>
        </tr>
        <tr>
            <td>Snipping Tool</td>
            <td>snippingtool</td>
        </tr>
        <tr>
            <td>Sound Recorder</td>
            <td>soundrecorder</td>
        </tr>
        <tr>
            <td>SQL Server Client Network Utility</td>
            <td>cliconfg</td>
        </tr>
        <tr>
            <td>Sticky Notes</td>
            <td>stikynot</td>
        </tr>
        <tr>
            <td>Stored User Names and Passwords</td>
            <td>credwiz</td>
        </tr>
        <tr>
            <td>Sync Center</td>
            <td>mobsync</td>
        </tr>
        <tr>
            <td>System Configuration</td>
            <td>msconfig</td>
        </tr>
        <tr>
            <td>System Configuration Editor</td>
            <td>sysedit5</td>
        </tr>
        <tr>
            <td>System Information</td>
            <td>msinfo32</td>
        </tr>
        <tr>
            <td>System Properties (Advanced Tab)</td>
            <td>systempropertiesadvanced</td>
        </tr>
        <tr>
            <td>System Properties (Computer Name Tab)</td>
            <td>systempropertiescomputername</td>
        </tr>
        <tr>
            <td>System Properties (Hardware Tab)</td>
            <td>systempropertieshardware</td>
        </tr>
        <tr>
            <td>System Properties (Remote Tab)</td>
            <td>systempropertiesremote</td>
        </tr>
        <tr>
            <td>System Properties (System Protection Tab)</td>
            <td>systempropertiesprotection</td>
        </tr>
        <tr>
            <td>System Restore</td>
            <td>rstrui</td>
        </tr>
        <tr>
            <td>Tablet PC Input Panel</td>
            <td>tabtip1</td>
        </tr>
        <tr>
            <td>Task Manager</td>
            <td>taskmgr</td>
        </tr>
        <tr>
            <td>Task Scheduler</td>
            <td>taskschd</td>
        </tr>
        <tr>
            <td>Trusted Platform Module (TPM) Management</td>
            <td>tpm</td>
        </tr>
        <tr>
            <td>User Account Control Settings</td>
            <td>useraccountcontrolsettings</td>
        </tr>
        <tr>
            <td>Utility Manager</td>
            <td>utilman</td>
        </tr>
        <tr>
            <td>Version Reporter Applet</td>
            <td>winver</td>
        </tr>
        <tr>
            <td>Volume Mixer</td>
            <td>sndvol</td>
        </tr>
        <tr>
            <td>Windows Activation Client</td>
            <td>slui</td>
        </tr>
        <tr>
            <td>Windows Anytime Upgrade Results</td>
            <td>windowsanytimeupgraderesults</td>
        </tr>
        <tr>
            <td>Windows Contacts</td>
            <td>wab1</td>
        </tr>
        <tr>
            <td>Windows Disc Image Burning Tool</td>
            <td>isoburn</td>
        </tr>
        <tr>
            <td>Windows DVD Maker</td>
            <td>dvdmaker1</td>
        </tr>
        <tr>
            <td>Windows Easy Transfer</td>
            <td>migwiz1</td>
        </tr>
        <tr>
            <td>Windows Explorer</td>
            <td>explorer</td>
        </tr>
        <tr>
            <td>Windows Fax and Scan</td>
            <td>wfs</td>
        </tr>
        <tr>
            <td>Windows Features</td>
            <td>optionalfeatures</td>
        </tr>
        <tr>
            <td>Windows Firewall with Advanced Security</td>
            <td>wf</td>
        </tr>
        <tr>
            <td>Windows Help and Support</td>
            <td>winhlp32</td>
        </tr>
        <tr>
            <td>Windows Journal</td>
            <td>journal1</td>
        </tr>
        <tr>
            <td>Windows Media Player</td>
            <td>dvdplay2</td>
        </tr>
        <tr>
            <td>wmplayer1</td>
        </tr>
        <tr>
            <td>Windows Memory Diagnostic Scheduler</td>
            <td>mdsched</td>
        </tr>
        <tr>
            <td>Windows Mobility Center</td>
            <td>mblctr</td>
        </tr>
        <tr>
            <td>Windows Picture Acquisition Wizard</td>
            <td>wiaacmgr</td>
        </tr>
        <tr>
            <td>Windows PowerShell</td>
            <td>powershell1</td>
        </tr>
        <tr>
            <td>Windows PowerShell ISE</td>
            <td>powershell_ise1</td>
        </tr>
        <tr>
            <td>Windows Remote Assistance</td>
            <td>msra</td>
        </tr>
        <tr>
            <td>Windows Repair Disc</td>
            <td>recdisc</td>
        </tr>
        <tr>
            <td>Windows Script Host</td>
            <td>wscript</td>
        </tr>
        <tr>
            <td>Windows Update</td>
            <td>wuapp</td>
        </tr>
        <tr>
            <td>Windows Update Standalone Installer</td>
            <td>wusa</td>
        </tr>
        <tr>
            <td>WMI Management</td>
            <td>wmimgmt</td>
        </tr>
        <tr>
            <td>WMI Tester</td>
            <td>wbemtest</td>
        </tr>
        <tr>
            <td>WordPad</td>
            <td>write</td>
        </tr>
        <tr>
            <td>XPS Viewer</td>
            <td>xpsrchvw</td>
        </tr>
    </tbody>
    </table>
    <p>{`[1]`}{` This run command can not be executed from the Command Prompt because the file is not in the default Windows path. However, it can be run from the Windows Search box or the Run box.`}</p>
    <p>{`[2]`}{` The dvdplay run command opens Windows Media Player and automatically starts to play the DVD movie in the primary DVD drive.`}</p>
    <p>{`[3]`}{` You must follow the fontview run command with the name of the font that you wish to see.`}</p>
    <p>{`[4]`}{` When you execute the regedt32 run command, it simply forwards to regedit and executes that program instead. Two distinct versions of Registry Editor did exist in some earlier versions of Windows.`}</p>
    <p>{`[5]`}{` This run command is not available in 64-bit versions of Windows 7.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      